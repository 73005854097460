import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const contactUsPageSlice = createSlice({
  name: "contactUs",
  initialState,
  reducers: {
    sendEmailRequest: (state, action) => {
      state.loading = true;
      state.data = {};
      state.error = false;
    },
    sendEmailSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = false;
    },
    sendEmailFailure: (state, action) => {
      state.loading = false;
      state.data = {};
      state.error = action.payload;
    },
  },
});

export const ContactUsPageAction = contactUsPageSlice.actions;
export default contactUsPageSlice;
