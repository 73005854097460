import { getUserSubscription } from "../Profile/ProfileActions";
import { SubscriptionsPageAction } from "./SubscriptionsReducers";
import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getSubscriptions = () => async (dispatch) => {
  try {
    dispatch(SubscriptionsPageAction.getSubscriptionsDataRequest());
    const response = await axios.get(`${API_BASE_URL}/packages`);
    dispatch(
      SubscriptionsPageAction.getSubscriptionsDataSuccess(response.data)
    );
  } catch (e) {
    dispatch(SubscriptionsPageAction.getSubscriptionsDataFailure(e.message));
  }
};

export const addSubscription = (subscriptionDetail) => async (dispatch) => {
  const { subscriptionDetails, profileData } = subscriptionDetail;

  console.log({
    userId: profileData.id,
    packageId: subscriptionDetails?.subscription?.id,
    productIds: subscriptionDetails?.perfumes.map((product) => product.id),
    email: subscriptionDetail?.profileData?.email,
    priceId: subscriptionDetails?.subscription?.stripePriceCode,
  });
  try {
    dispatch(SubscriptionsPageAction.getSubscriptionsDataRequest());
    const response = await axios.post(`${API_BASE_URL}/subscriptions/create`, {
      userId: profileData.id,
      packageId: subscriptionDetails?.subscription?.id,
      productIds: subscriptionDetails?.perfumes.map((product) => product.id),
      email: subscriptionDetail?.profileData?.email,
      priceId: subscriptionDetails?.subscription?.stripePriceCode,
    });
    window.location.href = response.data.url;
    dispatch(
      SubscriptionsPageAction.getSubscriptionsDataSuccess(response.data)
    );
  } catch (e) {
    console.log(e.message);
    dispatch(SubscriptionsPageAction.getSubscriptionsDataFailure(e.message));
  }
};

export const removeSubscription = (subscription) => async (dispatch) => {
  console.log("Removing subscription, subscription:", subscription);

  try {
    dispatch(SubscriptionsPageAction.removeSubscriptionDataRequest());
    const response = await axios.delete(
      `${API_BASE_URL}/subscriptions/delete/${subscription.id}?stripeId=${subscription.stripeSubId}`
    );
    dispatch(getUserSubscription());
    dispatch(
      SubscriptionsPageAction.removeSubscriptionDataSuccess(response.data)
    );
  } catch (e) {
    console.log(e.message);
    dispatch(SubscriptionsPageAction.removeSubscriptionDataFailure(e.message));
  }
};

export const editSubscription =
  (subscriptionId, newProducts) => async (dispatch) => {
    try {
      dispatch(SubscriptionsPageAction.editSubscriptionDataRequest());
      const response = await axios.patch(
        `${API_BASE_URL}/subscriptions/update/${subscriptionId}`,
        {
          products: newProducts,
        }
      );
      dispatch(getUserSubscription());
      dispatch(
        SubscriptionsPageAction.editSubscriptionDataSuccess(response.data)
      );
    } catch (e) {
      console.log(e.message);
      dispatch(SubscriptionsPageAction.editSubscriptionDataFailure(e.message));
    }
  };
