import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  stripeError: false,
  stripeData: [],
};

const stripePageSlice = createSlice({
  name: "stripe",
  initialState,
  reducers: {
    getStripeRequest: (state, action) => {
      state.loading = true;
      state.stripeError = false;
      state.stripeData = [];
    },
    getStripeSuccess: (state, action) => {
      state.loading = false;
      state.stripeError = false;
      state.stripeData = action.payload;
    },
    getStripeFailure: (state, action) => {
      state.loading = false;
      state.stripeError = action.payload;
      state.stripeData = [];
    },
  },
});

export const StripePageAction = stripePageSlice.actions;
export default stripePageSlice;
