import axios from "axios";
import { CheckoutActions } from "./CheckoutReducers";
import { emptyCart } from "../Cart/CartActions";

export const addOrder = (orderDetails) => async (dispatch) => {
  try {
    dispatch(CheckoutActions.addOrderRequest());
    const response = await axios.post(
      process.env.REACT_APP_API_BASE_URL + "/orders/create",
      orderDetails
    );

    window.location.href = response.data.url;
    dispatch(CheckoutActions.addOrderSuccess(response.data));
  } catch (e) {
    dispatch(CheckoutActions.addOrderFailure(e.message));
  }
};
