import React, { useEffect, useState } from "react";
import "./style.css";
import Background from "../../Assets/ContactUs/Background.png";
import { useDispatch, useSelector } from "react-redux";
import { sendEmail } from "../../Redux/ContactUs/ContactUsActions";
import { toast } from "react-toastify";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(
    (store) => store.contactUsReducer
  );

  const handleOnChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      !formData.name.trim() ||
      !formData.email.trim() ||
      !formData.message.trim()
    ) {
      toast.error("Please fill all required fields ");
      return;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      toast.error("Invalid email format.");
      return;
    }

    if (!loading) {
      dispatch(sendEmail(formData));
    }
  };

  useEffect(() => {
    if (data && !loading && !error) {
      setFormData({
        name: "",
        email: "",
        message: "",
      });
    }
  }, [data, loading, error]);

  return (
    <div className="bg-[#313131] w-full text-white">
      <div className="w-4/5 mx-auto flex flex-col md:flex-row items-center gap-2 py-20">
        <div className="flex-1 ">
          <h1 className="text-4xl tracking-wide ">Perfumster can Help</h1>
          <h6 className="text-lg opacity-70">Talk to us</h6>

          <form onSubmit={onSubmit} className="mt-20 flex flex-col gap-10">
            <div className="flex flex-row gap-2 border-b-2">
              <label htmlFor="" className="opacity-70">
                Name
              </label>
              <input
                name="name"
                type="text"
                value={formData.name}
                className="flex-1 bg-transparent outline-none "
                onChange={handleOnChange}
              />
            </div>

            <div className="flex flex-row gap-2 border-b-2">
              <label htmlFor="" className="opacity-70">
                Email
              </label>
              <input
                name="email"
                type="text"
                className="flex-1 bg-transparent outline-none"
                value={formData.email}
                onChange={handleOnChange}
              />
            </div>

            <div className="flex flex-row gap-2">
              <textarea
                name="message"
                type="text"
                placeholder="Message"
                rows={10}
                value={formData.message}
                className="flex-1 bg-transparent border outline-none"
                onChange={handleOnChange}
              />
            </div>

            <div
              className="
              flex flex-row gap-2
              items-center
              justify-end
            "
            >
              <button
                type="submit"
                className="bg-white text-black py-2 float-right w-1/3"
              >
                Send
              </button>
            </div>
          </form>
        </div>

        <div className="flex-1">
          <img src={Background} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
