import React from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { editSubscription } from "../../../Redux/Subscriptions/SubscriptionsActions";
import { customStyles } from "../../../Shared/customStyles";

const ConfirmChoiceModal = ({
  isOpen,
  closeModal,
  perfume, // chosen product
  subscription, // needed to get the products and send the new array of products
  chosenProduct, // product that is going to be changed
  closeChoosePerfumeModal,
}) => {
  const dispatch = useDispatch();
  const modalStyles = {
    ...customStyles,
    overlay: {
      ...customStyles.overlay,
      zIndex: 1102 || customStyles.overlay.zIndex, // Override zIndex
    },
  };

  const handleConfirm = () => {
    const { products } = subscription;

    const updatedProducts = products.map((product) => {
      if (product.id === chosenProduct.id) {
        return perfume;
      }
      return product;
    });
    dispatch(editSubscription(subscription.id, updatedProducts));
    closeModal();
    closeChoosePerfumeModal();
  };
  return (
    <div>
      <Modal isOpen={isOpen} onRequestClose={closeModal} style={modalStyles}>
        <div className="w-full h-full mx-auto text-white p-6 bg-gray-800 rounded-lg shadow-lg">
          <h1 className="text-4xl font-bold pb-6">Confirm Your Choice</h1>
          <p className="text-lg pb-8">
            Are you sure you want to select the perfume:{" "}
            <span className="font-semibold">{perfume?.name}</span>?
          </p>
          <div className="flex flex-col md:flex-row justify-end gap-4">
            <button
              onClick={closeModal}
              className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              onClick={handleConfirm}
              className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmChoiceModal;
