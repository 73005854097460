import { getProfileData } from "../Profile/ProfileActions";
import { AuthPageAction } from "./AuthReducer";
import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const loginUser = (user) => async (dispatch) => {
  if (localStorage.getItem("token")) {
    localStorage.removeItem("token");
  }
  try {
    dispatch(AuthPageAction.getLoginRequest());
    const { email, password } = user;
    const response = await axios.post(`${API_BASE_URL}/users/login`, {
      email,
      password,
    });
    const token = response.data.token;
    localStorage.setItem("token", token);
    dispatch(AuthPageAction.getLoginSuccess(response.data));
    dispatch(getProfileData());
  } catch (e) {
    dispatch(AuthPageAction.getLoginFailure(e?.response?.data?.message));
  }
};

export const resendVerificationLink = (email) => async (dispatch) => {
  try {
    dispatch(AuthPageAction.getResendVerificationRequest());
    const response = await axios.post(`${API_BASE_URL}/users/resendLink`, {
      email,
    });
    dispatch(AuthPageAction.getResendVerificationSuccess(response.data));
  } catch (e) {
    dispatch(
      AuthPageAction.getResendVerificationFailure(e.response.data.message)
    );
  }
};

export const signInUser = (user) => async (dispatch) => {
  try {
    dispatch(AuthPageAction.getSignUpRequest());
    const { username, email, password } = user;
    const res = await axios.post(`${API_BASE_URL}/users/signIn`, {
      username,
      email,
      password,
      role: "user",
    });

    dispatch(AuthPageAction.getSignUpSuccess(res.data));
  } catch (error) {
    const errorMessage = error?.response?.data?.message || error?.message;

    dispatch(AuthPageAction.getSignUpFailure(errorMessage));
  }
};

export const requestOTPCode = (email) => async (dispatch) => {
  try {
    dispatch(AuthPageAction.getRequestOtpRequest());
    const response = await axios.post(`${API_BASE_URL}/users/request-otp`, {
      email,
    });
    dispatch(AuthPageAction.getRequestOtpSuccess(response.data.message));
  } catch (error) {
    dispatch(AuthPageAction.getRequestOtpFailure(error.response.data.message));
  }
};

export const changePassword = (newPassword, otp, email) => async (dispatch) => {
  try {
    dispatch(AuthPageAction.getChangePasswordRequest());
    const response = await axios.post(`${API_BASE_URL}/users/reset-password`, {
      password: newPassword,
      otp,
      email,
    });
    dispatch(AuthPageAction.getChangePasswordSuccess(response.data.message));
  } catch (error) {
    dispatch(
      AuthPageAction.getChangePasswordFailure(error.response.data.message)
    );
  }
};
