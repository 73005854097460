import { createSlice } from "@reduxjs/toolkit";
import { emptyCart } from "./CartActions";

const loadCartFromStorage = () => {
  const cartItems = localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart"))
    : [];
  return cartItems;
};

const saveCartToStorage = (cart) => {
  localStorage.setItem("cart", JSON.stringify(cart));
};

const initialState = {
  loading: false,
  error: false,
  cart: loadCartFromStorage(),

  addToCartLoading: false,
  addToCartError: false,

  updateCartLoading: false,
  updateCartError: false,

  removeFromCartLoading: false,
  removeFromCartError: false,

  emptyCartLoading: false,
  emptyCartError: false,
  // addToCart: [],
  // updateCart: [],
  // removeFromCart: [],
};

const cartPageSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    // getCartDataRequest: (state, action) => {
    //   state.loading = true;
    //   state.cart = [];
    //   state.error = false;
    // },
    // getCartDataSuccess: (state, action) => {
    //   state.loading = false;
    //   state.error = false;
    // },
    // getCartDataFailure: (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload;
    // },

    addToCartRequest: (state, action) => {
      state.addToCartLoading = true;
      state.addToCartError = false;
    },
    addToCartSuccess: (state, action) => {
      state.addToCartLoading = false;
      var item = action.payload;
      const existingItem = state.cart.find(
        (cartItem) => cartItem.id === item.perfume.id
      );
      if (existingItem) {
        existingItem.quantity += item.quantity;
      } else {
        state.cart.push({
          id: item.perfume.id,
          perfume: item.perfume,
          quantity: item.quantity,
        });
      }
      saveCartToStorage(state.cart);
      state.addToCartError = false;
    },
    addToCartFailure: (state, action) => {
      state.addToCartLoading = false;
      state.addToCartError = action.payload;
    },

    increaseQuantityRequest: (state, action) => {
      state.updateCartLoading = true;
      state.updateCartError = false;
    },
    increaseQuantitySuccess: (state, action) => {
      state.updateCartLoading = false;

      const item = action.payload;

      const existingItem = state.cart.find(
        (cartItem) => cartItem.id === item.id
      );
      if (existingItem) {
        existingItem.quantity++;
      }
      saveCartToStorage(state.cart);

      state.updateCartError = false;
    },
    increaseQuantityFailure: (state, action) => {
      state.updateCartLoading = false;
      state.updateCartError = action.payload;
    },

    decreaseQuantityRequest: (state, action) => {
      state.updateCartLoading = true;
      state.updateCartError = false;
    },
    decreaseQuantitySuccess: (state, action) => {
      state.updateCartLoading = false;

      const item = action.payload;

      const existingItem = state.cart.find(
        (cartItem) => cartItem.id === item.id
      );
      if (existingItem) {
        if (existingItem.quantity !== 1) existingItem.quantity--;
      }
      saveCartToStorage(state.cart);
      state.updateCartError = false;
    },
    decreaseQuantityFailure: (state, action) => {
      state.updateCartLoading = false;
      state.updateCartError = action.payload;
    },

    removeFromCartRequest: (state, action) => {
      state.removeFromCartLoading = true;
      state.removeFromCartError = false;
    },
    removeFromCartSuccess: (state, action) => {
      state.removeFromCartLoading = false;
      const item = action.payload;
      state.cart = state.cart.filter((cartItem) => cartItem.id !== item.id);
      saveCartToStorage(state.cart);
      state.removeFromCartError = false;
    },
    removeFromCartFailure: (state, action) => {
      state.removeFromCartLoading = false;
      state.removeFromCartError = action.payload;
    },
    emptyCart: (state, action) => {
      state.emptyCartLoading = false;
      state.emptyCartError = false;
      state.cart = [];
      saveCartToStorage(state.cart);
    },
  },
});
export const CartPageActions = cartPageSlice.actions;
export default cartPageSlice;
