import React, { useEffect } from "react";
import "./style.css";
import { useState } from "react";
import Background from "../../Assets/Auth/background.webp";
import Google from "../../Assets/Auth/google.svg";
import Facebook from "../../Assets/Auth/facebook.svg";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  loginUser,
  resendVerificationLink,
  signInUser,
} from "../../Redux/Auth/AuthActions";
import { useNavigate } from "react-router-dom";

const Auth = () => {
  const [isSignUp, setIsSignUp] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [repeatedPass, setRepeatedPass] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);

  const { error, signup, login, resendVerificationError } = useSelector(
    (store) => store.authReducer
  );
  const validateEmail = (value) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(value);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      toast.error("Please enter all fields");
      return;
    }
    const validation = validateEmail(email);
    if (!validation) {
      toast.error("Please enter a valid email");
      return;
    }

    dispatch(loginUser({ email, password }));
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResendVerificationLink = (e) => {
    dispatch(resendVerificationLink(email));
  };
  useEffect(() => {
    if (error) {
      toast.error(
        <div>
          {error} <br></br>{" "}
          {error === "Please verify your email before logging in." ? (
            <span
              onClick={handleResendVerificationLink}
              style={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Resend verification link
            </span>
          ) : (
            ""
          )}
        </div>
      );
      return;
    }

    // if (error) {
    //   console.error(error);
    //   toast.error(error);
    //   return;
    // }
    if (signup.message) {
      toast.success(signup.message);
      setIsSignUp(false);
      reset();
      return;
    }
    if (login.message) {
      toast.success(login.message);
      navigate("/");
      reset();
      return;
    }
  }, [error, signup, login, navigate, resendVerificationError]);

  const handleSignIn = (e) => {
    e.preventDefault();
    if (username === "" || email === "" || password === "") {
      toast.error("Please enter all fields");
      return;
    }
    const validation = validateEmail(email);
    if (!validation) {
      toast.error("Please enter a valid email");
      return;
    }
    if (password !== repeatedPass) {
      toast.error("Passwords do not match");
      return;
    }
    dispatch(signInUser({ username, email, password }));
    //reset();
  };

  const handleChangeAuth = () => {
    reset();
    setIsSignUp(!isSignUp);
  };

  const handleForgotPassword = () => {
    navigate("/request-otp");
  };

  const reset = () => {
    setEmail("");
    setUsername("");
    setPassword("");
    setRepeatedPass("");
  };
  return (
    <div className="w-full bg-[#313131] text-white py-20 ">
      {isSignUp && (
        <div className="w-4/5 mx-auto flex flex-col lg:flex-row gap-9 ">
          <div className="flex-1 flex flex-col gap-3 ">
            <div className="flex flex-col gap-1">
              <h1 className="text-2xl md:text-4xl  ">Create Account</h1>
              <h2 className="opacity-60">Lets get started with your account</h2>
            </div>

            <form action="" className="flex flex-col gap-3">
              <div className="flex flex-col  md:flex-row gap-2">
                <div className="flex-1 flex flex-col gap-2">
                  <label className="opacity-60">Name</label>
                  <input
                    type="text"
                    value={username}
                    className="p-1 py-2 rounded-md text-black outline-none opacity-60"
                    placeholder="What is Your name"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div className="flex-1 flex flex-col gap-2">
                  <label className="opacity-60">Email</label>
                  <input
                    type="email"
                    value={email}
                    className="p-1 py-2 rounded-md text-black outline-none opacity-60"
                    placeholder="What is Your email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-2">
                <div className="flex-1 flex flex-col gap-2">
                  <label className="opacity-60">Password</label>
                  <input
                    type="password"
                    value={password}
                    className="p-1 py-2 rounded-md text-black outline-none opacity-60"
                    placeholder="Create Your Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="flex-1 flex flex-col gap-2">
                  <label className="opacity-60">Repeat Password</label>
                  <input
                    type="password"
                    value={repeatedPass}
                    className="p-1 py-2 rounded-md text-black outline-none opacity-60"
                    placeholder="Repeat Password"
                    onChange={(e) => setRepeatedPass(e.target.value)}
                  />
                </div>
              </div>

              <div className="flex flex-col  md:flex-row justify-between w-full gap-y-7">
                <div className="flex flex-row gap-2 items-center">
                  <input type="checkbox" />
                  <label htmlFor="">You accept terms of use</label>
                </div>

                <button
                  type="submit"
                  className="bg-black text-white p-2 px-4 rounded-md text-center cursor-pointer"
                  onClick={handleSignIn}
                >
                  <span>Create Account</span>
                </button>
              </div>
            </form>

            <div className="bg-slate-300 rounded-md p-4">
              <h1>Sign up using Social Media</h1>

              <div className="flex flex-row gap-2 mt-3">
                <div className="rounded-full bg-slate-400 p-3">
                  <img src={Google} alt="" width={30} />
                </div>

                <div className="rounded-full bg-slate-400 p-3">
                  <img src={Facebook} alt="" width={30} />
                </div>
              </div>
            </div>

            <div>
              <h1>
                Already have an account ?{" "}
                <span
                  className="underline font-bold cursor-pointer"
                  onClick={handleChangeAuth}
                >
                  Login
                </span>
              </h1>
            </div>
          </div>

          <div className="flex-1 ">
            {/* Background Image */}
            <img
              src={Background}
              alt=""
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      )}

      {!isSignUp && (
        <div className="w-4/5 mx-auto flex flex-col lg:flex-row gap-9">
          <div className="flex-1 flex flex-col gap-3">
            <div className="flex flex-col gap-1">
              <h1 className="text-2xl md:text-4xl ">Sign in to your account</h1>
              <h2 className="opacity-60">Continue where you left off</h2>
            </div>

            <form onSubmit={handleLogin} className="flex flex-col gap-3">
              <div className="flex flex-col gap-2 w-full md:w-2/3">
                <label className="opacity-60">Email</label>
                <input
                  type="email"
                  value={email}
                  className="p-1 py-2 rounded-md text-black outline-none opacity-60"
                  placeholder="What is Your email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="flex flex-col gap-2 w-full md:w-2/3">
                <label className="opacity-60">Password</label>
                <input
                  type="password"
                  value={password}
                  className="p-1 py-2 rounded-md text-black outline-none opacity-60"
                  placeholder="Enter Your Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              {/* Forgot Password Link */}
              <div className="text-right w-full md:w-2/3">
                <span
                  className="text-sm text-blue-500 underline cursor-pointer"
                  onClick={handleForgotPassword}
                >
                  Forgot Password?
                </span>
              </div>

              <button
                className="w-2/3 md:w-1/4 bg-black text-white p-2 px-4 rounded-md text-center cursor-pointer"
                onClick={handleLogin}
                type="submit"
              >
                <span>Login</span>
              </button>
            </form>

            <div className="bg-slate-300 rounded-md p-4">
              <h1>Sign up using Social Media</h1>

              <div className="flex flex-row gap-2 mt-3">
                <div className="rounded-full bg-slate-400 p-3">
                  <img src={Google} alt="" width={30} />
                </div>

                <div className="rounded-full bg-slate-400 p-3">
                  <img src={Facebook} alt="" width={30} />
                </div>
              </div>
            </div>

            <div>
              <h1>
                Don't have an account?{" "}
                <span
                  className="underline font-bold cursor-pointer"
                  onClick={handleChangeAuth}
                >
                  Create Account
                </span>
              </h1>
            </div>
          </div>

          <div className="flex-1">
            <img
              src={Background}
              alt=""
              loading="lazy"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Auth;
