import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { changePassword } from "../../Redux/Auth/AuthActions";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthPageAction } from "../../Redux/Auth/AuthReducer";

const ChangePassword = () => {
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { changePasswordLoading, passwordChanged, changePasswordError } =
    useSelector((store) => store.authReducer);
  const dispatch = useDispatch();
  const location = useLocation();
  const { email } = location.state;
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password.length < 8) {
      toast.error("Password must be at least 8 characters long");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    if (!changePasswordLoading) {
      dispatch(changePassword(password, Number(otp), email));
    }
  };

  useEffect(() => {
    if (Object.keys(passwordChanged).length > 0) {
      console.log("Password changed successfully");
      toast.success("Password changed successfully");
      navigate("/auth");
      dispatch(AuthPageAction.resetPasswordChanged());
    }

    if (changePasswordError) {
      console.error("Error: " + changePasswordError);
      toast.error(changePasswordError);
      return;
    }
  }, [passwordChanged, changePasswordError, changePasswordLoading]);

  return (
    <div className="w-full bg-[#313131] text-white py-20 flex justify-center">
      <div className="w-4/5 md:w-2/3 lg:w-1/3 flex flex-col gap-6">
        <div className="text-center">
          <h1 className="text-2xl md:text-4xl ">Change Password</h1>
          <p className="opacity-60">
            Enter your new password and the OTP sent to your email.
          </p>
        </div>

        <form
          onSubmit={handleSubmit}
          className="flex flex-col gap-4 items-center"
        >
          {/* OTP Field */}
          <div className="w-full flex flex-col gap-2">
            <label className="opacity-60">OTP Code</label>
            <input
              type="text"
              value={otp}
              className="w-full p-2 rounded-md text-black outline-none opacity-60"
              placeholder="Enter OTP"
              onChange={(e) => setOtp(e.target.value)}
              required
            />
          </div>

          {/* Password Field */}
          <div className="w-full flex flex-col gap-2">
            <label className="opacity-60">New Password</label>
            <input
              type="password"
              value={password}
              className="w-full p-2 rounded-md text-black outline-none opacity-60"
              placeholder="Enter new password"
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>

          {/* Confirm Password Field */}
          <div className="w-full flex flex-col gap-2">
            <label className="opacity-60">Re-enter Password</label>
            <input
              type="password"
              value={confirmPassword}
              className="w-full p-2 rounded-md text-black outline-none opacity-60"
              placeholder="Re-enter new password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="bg-black text-white p-2 px-6 rounded-md text-center cursor-pointer"
          >
            Change Password
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
