import React, { useEffect, useState } from "react";
import "./style.css";
import Footer1 from "../../Components/Footer1/footer1";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addOrder } from "../../Redux/Checkout/CheckoutActions";
import { getProfileData } from "../../Redux/Profile/ProfileActions";
import { useNavigate } from "react-router-dom";
import { isTokenValid } from "../../utils/verifyToken";
import { countries } from "../../Shared/countries";

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cart } = useSelector((store) => store.cartReducer);
  const { profileData } = useSelector((store) => store.profileReducer);

  useEffect(() => {
    if (!isTokenValid()) {
      navigate("/auth");
    }
  }, [navigate]);

  useEffect(() => {
    dispatch(getProfileData());
  }, [dispatch]);

  const [total, setTotal] = useState();

  useEffect(() => {
    if (cart.length === 0) {
      navigate("/products");
    }
    if (cart) {
      let total = 0;
      cart.forEach((element) => {
        total += element.perfume.price * element.quantity;
      });
      setTotal(total);
    }
  }, [cart, navigate]);

  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [apartment, setApartment] = useState("");
  const [city, setCity] = useState("");

  const formatAddress = () => {
    return `${address}, ${apartment ? apartment + ", " : ""}${city}, 
     
    , ${country}`;
  };

  const checkInputs = () => {
    if (country === "" || address === "" || apartment === "" || city === "") {
      toast.error("Please fill all the fields");
      return;
    }

    dispatch(
      addOrder({
        email: profileData.email,
        userId: profileData.id,
        location: formatAddress(),
        date: new Date().toISOString(),
        quantity: cart.reduce((acc, product) => acc + product.quantity, 0),
        status: "Pending",
        amount: total,
        products: cart.map((order) => ({
          product: order.perfume,
          quantity: order.quantity,
        })),
      })
    );
  };
  return (
    <>
      <div className="w-full bg-[#313131] text-white py-20 ">
        {/* Title */}
        <div className="w-4/5 mx-auto">
          <h1 className="text-5xl  ">Checkout</h1>
        </div>

        <div className="w-4/5 mx-auto flex flex-col lg:flex-row gap-2 gap-y-10">
          {/* Delivery Address and Payment */}
          <div className="flex-1 flex flex-col gap-2 mt-10 ">
            <div className="flex-1">
              <h1 className="text-3xl">Delivery Address</h1>

              <form
                id="deliveryAddress"
                action=""
                className="flex flex-col gap-2 w-full mt-2"
              >
                <select
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  className="bg-transparent"
                >
                  <option value="" className="text-white">
                    Select Country
                  </option>
                  {countries.map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </select>

                <input
                  type="text"
                  placeholder="Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Apartment, suite, etc. (optional)"
                  value={apartment}
                  onChange={(e) => setApartment(e.target.value)}
                />

                <div className="flex flex-col xl:flex-row gap-2">
                  <input
                    type="text"
                    placeholder="City"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    className="flex-1"
                  />
                </div>
              </form>
            </div>
          </div>

          <div className="flex-1 border_2 p-2">
            {cart.map((element, index) => (
              <div key={index}>
                <div className="flex flex-row justify-between">
                  <div className="flex-1 flex flex-col md:flex-row items-center gap-2">
                    <img
                      src={
                        process.env.REACT_APP_BASE_URL + element.perfume.image
                      }
                      width={60}
                      alt=""
                    />
                    <div className="flex flex-col items-center gap-3">
                      <span className="text-center">
                        {element.perfume.name}
                      </span>
                      <span className="opacity-70">
                        QTY : {element.quantity}
                      </span>
                    </div>
                  </div>

                  <div className="flex flex-1 items-center justify-end">
                    <span className="opacity-70">
                      $ {element.perfume.price}
                    </span>
                  </div>
                </div>
                <hr />
              </div>
            ))}

            <div className="flex flex-row justify-between pt-5">
              <div className="flex-1 flex flex-row items-center gap-2">
                <span className="text-2xl">Total</span>
              </div>

              <div className="flex flex-1 items-center justify-end">
                <span>$ {total}</span>
              </div>
            </div>

            <div
              className="p-1 px-5 border cursor-pointer mt-12 w-1/2 text-center bg-white text-black float-right"
              onClick={checkInputs}
            >
              <span>Checkout</span>
            </div>
          </div>
        </div>
      </div>

      <Footer1 />
    </>
  );
};

export default Checkout;
