import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Img } from "react-image";
import Loader from "../Loader/loader";
import "./style.css";
const PerfumeCard = ({ perfume }) => {
  const navigate = useNavigate();

  const navigateToAddToCart = () => {
    navigate("/addToCart", { state: { perfume } });
    window.scrollTo(0, 0);
  };

  return (
    <div
      className="flex flex-col justify-between items-stretch gap-3 h-full "
      onClick={navigateToAddToCart}
    >
      {/* Image Section */}
      <div className="bg-[#454545] h-[50vh] flex items-center justify-center hover:scale-105 transition-transform duration-300">
        <div className="flex items-center justify-center ">
          <div className="flex justify-center ">
            <Img
              src={process.env.REACT_APP_BASE_URL + perfume?.image}
              alt=""
              className="w-2/3"
              loader={<Loader />}
            />
          </div>
        </div>
      </div>

      {/* Content Section */}
      <div className="flex-grow flex justify-between flex-col gap-2 text-white">
        <h1 className={`text-lg h-auto  opacity-80 `}>{perfume?.name}</h1>

        <h3 className=" opacity-80 text-xl">${perfume?.price}</h3>
      </div>

      {/* Buttons */}
      <div className="flex flex-col sm:flex-row gap-3 font-thin justify-center">
        <div className="border text-white text-center p-1 px-5 cursor-pointer">
          <span className="text-sm">Add to cart</span>
        </div>
      </div>
    </div>
  );
};

export default PerfumeCard;
