import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { updateUserInfo } from "../../../Redux/Profile/ProfileActions";
import { toast } from "react-toastify";
import { customStyles } from "../../../Shared/customStyles";

const EditUserInfoModal = ({ isOpen, closeModal, profileData: userData }) => {
  const [email, setEmail] = useState(userData?.email);
  const [image, setImage] = useState(userData?.image);
  const [imagePreview, setImagePreview] = useState(userData?.image);
  const [phoneNumber, setPhoneNumber] = useState(userData?.phone_number);
  const [dateOfBirth, setDateOfBirth] = useState(userData?.date_of_birth);
  const [country, setCountry] = useState(userData?.country);
  const [profession, setProfession] = useState(userData?.profession);
  const dispatch = useDispatch();
  useEffect(() => {
    setEmail(userData?.email);
    setImage(userData?.profileImage);
    setPhoneNumber(userData?.phone_number);
    const formattedDate = userData?.date_of_birth
      ? new Date(userData.date_of_birth).toISOString().split("T")[0]
      : "";
    setDateOfBirth(formattedDate);
    setCountry(userData?.country);
    setProfession(userData?.profession);
  }, [userData]);

  useEffect(() => {
    console.log(image, imagePreview);
  }, [image, imagePreview]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => setImagePreview(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\+[0-9]{1,4}-[0-9]{8,15}$/;
    return phoneRegex.test(phone);
  };

  const handleEditUserInfo = (e) => {
    e.preventDefault();

    if (!validatePhoneNumber(phoneNumber) && phoneNumber !== "") {
      toast.error(
        "Please enter a valid phone number in the format: +<country_code>-<local_number> (e.g., +123-4567891)"
      );
      return;
    }

    const updatedInfo = {
      id: userData.id,
      profileImage: image,
      phoneNumber,
      dateOfBirth,
      country,
      profession,
    };
    dispatch(updateUserInfo(updatedInfo));
    closeModal();
  };

  const modalStyles = {
    ...customStyles,
    overlay: {
      ...customStyles.overlay,
      zIndex: 1100 || customStyles.overlay.zIndex, // Override zIndex
    },
  };
  return (
    <Modal isOpen={isOpen} style={modalStyles} onRequestClose={closeModal}>
      <div className="p-2">
        <div className="bg-[#1D1D1D] rounded-md flex flex-col lg:flex-row gap-3 p-5  max-w-4xl mx-auto w-full">
          <div className="lg:w-1/3 flex flex-col justify-center items-center">
            <div className="rounded-full w-36 h-36 md:w-48 md:h-48 ">
              <img
                src={imagePreview}
                alt="Profile"
                width={200}
                className="rounded-full h-full w-full object-cover"
              />
            </div>
            <div className="flex flex-col items-center gap-2 mt-2">
              {/* Styled button for file input */}
              <label
                htmlFor="imageInput"
                className="bg-blue-500 text-white py-2 px-4 rounded-md cursor-pointer"
              >
                Choose Image
              </label>
              <input
                id="imageInput"
                type="file"
                accept="image/*"
                className="hidden text-ellipsis"
                onChange={handleImageChange}
              />
              {imagePreview && (
                <span
                  className="text-white text-sm w-40 truncate text-center"
                  title={imagePreview} // Tooltip for full name
                >
                  {imagePreview}
                </span>
              )}
            </div>
          </div>

          <div className="lg:w-2/3 flex flex-col gap-2 text-white">
            <div className="flex flex-col lg:flex-row gap-2">
              <div className="flex-1 flex flex-col">
                <h1>Email</h1>
                <div className="bg-white rounded-md py-1 text-black pl-2 opacity-60 truncate">
                  <span className="block overflow-hidden text-ellipsis whitespace-nowrap">
                    {email}
                  </span>
                </div>
              </div>

              <div className="flex-1 flex flex-col">
                <h1>Phone Number</h1>
                <input
                  type="text"
                  className="bg-white rounded-md py-1 text-black pl-2"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
            </div>

            <div className="flex flex-col lg:flex-row gap-2">
              <div className="flex-1 flex flex-col">
                <h1>Date of Birth</h1>
                <input
                  type="date"
                  className="bg-white rounded-md py-1 text-black pl-2"
                  value={dateOfBirth}
                  onChange={(e) => setDateOfBirth(e.target.value)}
                />
              </div>

              <div className="flex-1 flex flex-col">
                <h1>Country</h1>
                <input
                  type="text"
                  className="bg-white rounded-md py-1 text-black pl-2"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                />
              </div>
            </div>

            <div className="flex flex-col lg:flex-row gap-2">
              <div className="flex-1 flex flex-col">
                <h1>Profession</h1>
                <input
                  type="text"
                  className="bg-white rounded-md py-1 text-black pl-2"
                  value={profession}
                  onChange={(e) => setProfession(e.target.value)}
                />
              </div>

              <div className="flex-1 flex flex-col">
                <h1>Password</h1>
                <div className="bg-white rounded-md py-1 text-black pl-2 opacity-60">
                  ********
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end mt-4 gap-2">
          <button
            type="submit"
            className="bg-blue-500 text-white py-2 px-4 rounded-md cursor-pointer"
            onClick={handleEditUserInfo}
          >
            Save Changes
          </button>
          <button
            className="bg-black text-white py-2 px-4  rounded-md text-center cursor-pointer"
            onClick={closeModal}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditUserInfoModal;
