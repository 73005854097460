export const customStyles = {
  content: {
    inset: "15px",
    padding: "0px",
    backgroundColor: "#313131",
    color: "white",
    borderRadius: "10px",
  },
  overlay: {
    zIndex: 1100,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};
