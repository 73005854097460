import React, { useEffect, useState } from "react";
import Recommended from "../../Assets/Men/recommended.webp";
import PerfumeCard from "../../Components/PerfumeCard/perfumeCard";
import InitialBg from "../../Assets/Men/initialBg.png";
import SpecialCard from "../../Components/specialsCard";
import "./style.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../Redux/Products/ProductsActions";
import { getMenData } from "../../Redux/ForHim/ForHimActions";
import Footer1 from "../../Components/Footer1/footer1";

const ForHim = () => {
  const [chosen, setChosen] = useState("5ml");
  const [perfumes, setPerfumes] = useState([]);
  const dispatch = useDispatch();
  const { menData } = useSelector((store) => store.forHimReducer);
  const { products } = useSelector((store) => store.productsReducer);

  const [homeSection, setHomeSection] = useState([]);
  const [recommendedPerfumes, setRecommendedPerfumes] = useState([]);
  const [specialPerfumes, setSpecialPerfumes] = useState([]);
  const [specialBackgrounds, setSpecialBackgrounds] = useState([]);

  const special_perfumes_data = [
    {
      brand: "Dior",
      tagline: "A Symphony of Elegance and Power",
      name: "Miss Dior",
      description:
        "An enchanting floral blend of Grasse rose, peony, and lily of the valley, balanced with a base of creamy sandalwood, embodying romance and sophistication.",
    },
    {
      brand: "Creed",
      tagline: "Legendary Scents for Timeless Appeal",
      name: "Aventus",
      description:
        "A dynamic and bold fragrance, blending blackcurrant, Italian bergamot, apple, and pineapple, layered with musk, oakmoss, and ambergris for a rich, unforgettable aroma.",
    },
    {
      brand: "Tom Ford",
      tagline: "Redefining Luxury with Every Scent",
      name: "Noir de Noir",
      description:
        "A sensual blend of black rose, truffle, and vanilla, layered with patchouli and oud wood, creating a dark, mysterious, and opulent signature scent.",
    },
  ];

  useEffect(() => {
    dispatch(getMenData());
    dispatch(getProducts());
  }, [dispatch]);

  useEffect(() => {
    if (menData.data) {
      setHomeSection(
        menData.data.filter((section) => section.section === "home")
      );
      setRecommendedPerfumes(
        menData.data.filter((section) => section.section === "recommended")
      );
      setSpecialBackgrounds(
        menData.data.filter(
          (section) => section.section === "special_backgrounds"
        )
      );
      setSpecialPerfumes(
        menData.data.filter((section) => section.section === "special_perfumes")
      );
    }

    if (products.data) {
      // Filter and randomly pick up to 7 perfumes
      const filteredPerfumes = products?.data?.filter(
        (perfume) =>
          perfume.category === "Male" && perfume.perfume_size === chosen
      );
      const randomPerfumes = filteredPerfumes
        .sort(() => Math.random() - 0.5) // Randomize array order
        .slice(0, 7); // Take the first 7 items
      setPerfumes(randomPerfumes);
    }
  }, [menData, products, chosen]);

  useEffect(() => {
    if (products.data) {
      // Filter and randomly pick up to 7 perfumes
      const filteredPerfumes = products?.data?.filter(
        (perfume) =>
          perfume.category === "Male" && perfume.perfume_size === chosen
      );
      const randomPerfumes = filteredPerfumes
        .sort(() => Math.random() - 0.5) // Randomize array order
        .slice(0, 7); // Take the first 7 items
      setPerfumes(randomPerfumes);
    }
  }, [chosen, products]);

  return (
    <>
      <div className="w-full bg-[#313131] pb-10">
        {/* Heading Image with Text */}
        <div
          className="w-full h-screen bg-cover bg-right flex items-start justify-center"
          style={{
            backgroundImage: `url(${
              homeSection[0]?.content
                ? process.env.REACT_APP_BASE_URL + homeSection[0]?.content
                : InitialBg
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        >
          {/* Text */}
          <div className="w-full h-full px-2 lg:px-0 lg:w-4/5 mx-auto pt-5 flex flex-col justify-start md:justify-center gap-2">
            <h1 className="text-white text-sm font-thin">Men Colognes</h1>
            <h2 className="text-white text-4xl md:text-6xl font-thin w-full lg:w-2/3 ">
              Discover the Essence <br /> of Masculinity
            </h2>
            <p className="w-1/3 hidden md:block text-white font-thin opacity-80 text-sm">
              Rhoncus morbi et augue nec, in id ullamcorper at sit. Condimentum
              sit nunc in eros scelerisque sed. Commodo in viverra nunc,
              ullamcorper ut. Non, amet, aliquet scelerisque nullam sagittis,
              pulvinar. Fermentum scelerisque sit consectetur hac mi. Mollis leo
              eleifend ultricies purus iaculis.
            </p>
            <div className="flex flex-row gap-2 mt-5">
              <Link to={"/products"}>
                <div className="bg-white text-black p-2 px-3 md:p-3 md:px-6 font-thin text-sm cursor-pointer">
                  Explore our collection
                </div>
              </Link>
            </div>
          </div>
        </div>

        {/* Highly Recommended Image + Title */}
        <div className="py-9">
          <div className="w-full text-center">
            <h1 className="text-white text-3xl md:text-5xl">
              Highly recommended
            </h1>
          </div>

          <div className="w-full md:w-4/5 mx-auto mt-10 relative">
            <img src={Recommended} alt="" className="w-full h-full" />

            <img
              src={
                process.env.REACT_APP_BASE_URL + recommendedPerfumes[0]?.content
              }
              alt="Perfume"
              class="perfume"
            />

            <img
              src={
                process.env.REACT_APP_BASE_URL + recommendedPerfumes[1]?.content
              }
              alt="Perfume"
              class="perfume_2"
            />
          </div>
        </div>

        {/* Specials */}
        <div className="py-9">
          <div className="w-full text-center">
            <h1 className="text-white text-3xl md:text-5xl">Specials</h1>
          </div>

          <div className="w-4/5 m-auto mt-10 grid grid-cols-1 gap-2 custom-grid ">
            {specialPerfumes.map((element, index) => (
              <div key={index} className="w-full flex">
                <SpecialCard
                  index={index + 1}
                  perfume={element}
                  backgroundImage={specialBackgrounds[index]?.content}
                  special_perfumes_data={special_perfumes_data[index]}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Product List */}
        <div className="py-9">
          <div className="w-full text-center text-white">
            <h1 className=" text-xl md:text-3xl">Product List</h1>
          </div>

          <div className="w-2/3 md:1/2 lg:w-1/3 bg-[#454545] flex flex-row items-center justify-center m-auto mt-10 text-center">
            <div
              className={`${
                chosen === "5ml" ? "text-black bg-white" : "text-slate-200"
              } flex-1 p-3 px-5 md:py-4 md:px-10 cursor-pointer`}
              onClick={() => setChosen("5ml")}
            >
              <span>5ml</span>
            </div>
            <div
              className={`${
                chosen === "10ml" ? "text-black bg-white" : "text-slate-200 "
              } flex-1  p-3 px-5 md:py-4 md:px-10 cursor-pointer`}
              onClick={() => setChosen("10ml")}
            >
              <span>10ml</span>
            </div>
          </div>
        </div>

        <div className="w-11/12 mx-auto py-10">
          <div className="w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4  lg:grid-cols-4  gap-4 gap-y-10">
            {perfumes.map((element, index) => (
              <PerfumeCard perfume={element} key={index} />
            ))}
          </div>
        </div>

        <div className="w-full flex flex-row items-center justify-center mt-10">
          <Link to={"/products"}>
            <div className="p-2 bg-white text-black cursor-pointer">
              Explore our collection
            </div>
          </Link>
        </div>
      </div>

      <Footer1 />
    </>
  );
};

export default ForHim;
