import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: false,
  subscriptions: [],

  deleteSubscriptionsLoading: false,
  deleteSubscriptionsError: false,
  deleteSubscriptionsData: [],
};

const subscriptionsPageSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    getSubscriptionsDataRequest: (state, action) => {
      state.loading = true;
      state.error = false;
      state.subscriptions = [];
    },
    getSubscriptionsDataSuccess: (state, action) => {
      state.loading = false;
      state.error = false;
      state.subscriptions = action.payload;
    },
    getSubscriptionsDataFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.subscriptions = [];
    },

    addSubscriptionDataRequest: (state, action) => {
      state.loading = true;
      state.error = false;
    },
    addSubscriptionDataSuccess: (state, action) => {
      state.loading = false;
      state.error = false;
      state.subscriptions = action.payload;
    },
    addSubscriptionDataFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    removeSubscriptionDataRequest: (state, action) => {
      state.deleteSubscriptionsLoading = true;
      state.deleteSubscriptionsError = false;
    },
    removeSubscriptionDataSuccess: (state, action) => {
      state.deleteSubscriptionsLoading = false;
      state.deleteSubscriptionsError = false;
      state.deleteSubscriptionsData = action.payload;
    },
    removeSubscriptionDataFailure: (state, action) => {
      state.deleteSubscriptionsLoading = false;
      state.deleteSubscriptionsError = action.payload;
      state.deleteSubscriptionsData = [];
    },

    editSubscriptionDataRequest: (state, action) => {
      state.loading = true;
      state.error = false;
    },
    editSubscriptionDataSuccess: (state, action) => {
      state.loading = false;
      state.error = false;
      state.subscriptions = action.payload;
    },
    editSubscriptionDataFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const SubscriptionsPageAction = subscriptionsPageSlice.actions;
export default subscriptionsPageSlice;
