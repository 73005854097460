import React, { useEffect } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { removeSubscription } from "../../../Redux/Subscriptions/SubscriptionsActions";
import Loader from "../../../Components/Loader/loader";
import { customStyles } from "../../../Shared/customStyles";
const RemoveSubscriptionModal = ({ isOpen, onClose, subscription }) => {
  const dispatch = useDispatch();
  const { deleteSubscriptionsLoading, deleteSubscriptionsData } = useSelector(
    (store) => store.subscriptionsReducer
  );

  const modalStyles = {
    ...customStyles,
    overlay: {
      ...customStyles.overlay,
      zIndex: 1100 || customStyles.overlay.zIndex, // Override zIndex
    },
  };

  useEffect(() => {
    if (!deleteSubscriptionsLoading && deleteSubscriptionsData) {
      onClose();
    }
  }, [deleteSubscriptionsData, deleteSubscriptionsLoading]);
  const handleConfirm = () => {
    dispatch(removeSubscription(subscription));
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={modalStyles}>
      {deleteSubscriptionsLoading && <Loader />}
      {!deleteSubscriptionsLoading && (
        <div className="w-full mx-auto text-white p-6 bg-gray-800 rounded-lg shadow-lg">
          <h1 className="text-4xl font-bold pb-6">Confirm Your Choice</h1>
          <p className="text-lg pb-8">
            Are you sure you want to cancel your subscription:{" "}
            <span className="font-semibold">{subscription.package?.name}</span>?
          </p>
          <div className="flex flex-col md:flex-row justify-end gap-4">
            <button
              onClick={onClose}
              className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              onClick={handleConfirm}
              className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
            >
              Confirm
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default RemoveSubscriptionModal;
