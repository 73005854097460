import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: false,
  checkout: {},
};

const checkoutPageSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    addOrderRequest: (state, action) => {
      state.loading = true;
      state.error = false;
      state.checkout = {};
    },
    addOrderSuccess: (state, action) => {
      state.loading = false;
      state.cart = action.payload;
      state.error = false;
    },
    addOrderFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.checkout = {};
    },
  },
});

export const CheckoutActions = checkoutPageSlice.actions;
export default checkoutPageSlice;
