import React from "react";
import { Link } from "react-router-dom";

const PaymentSuccess = () => {
  return (
    <div className="min-h-screen bg-[#313131] flex items-center justify-center text-center">
      <div className="text-white p-8">
        <h1 className="text-3xl md:text-8xl font-bold text-[#4CAF50] drop-shadow-lg mb-4">
          Success
        </h1>
        <h2 className="text-3xl font-semibold text-[#f1faee] mb-4">
          Payment Completed
        </h2>
        <p className="text-lg text-[#a8dadc] mb-8">
          Thank you for your purchase! Your payment was successful.
        </p>
        <Link to="/">
          <h1 className="inline-block px-6 py-3 bg-[#457b9d] text-white rounded-md transition duration-300 ease-in-out hover:bg-[#1d3557] text-lg">
            Go to Home
          </h1>
        </Link>
      </div>
    </div>
  );
};

export default PaymentSuccess;
