import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import ChoosePerfumeModal from "./Modals/ChoosePerfumeModal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { isTokenValid } from "../utils/verifyToken";
import { useDispatch, useSelector } from "react-redux";
import { getProfileData } from "../Redux/Profile/ProfileActions";
import { countries } from "../Shared/countries";
import { addSubscription } from "../Redux/Subscriptions/SubscriptionsActions";
import { customStyles } from "../Shared/customStyles";

const SubscriptionModal = ({ isOpenModal, closeModal, subscription }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProfileData());
  }, [dispatch]);
  useEffect(() => {
    if (isOpenModal && !isTokenValid(localStorage.getItem("token"))) {
      navigate("/auth");
    }
  });
  const { profileData } = useSelector((store) => store.profileReducer);
  const modalStyles = {
    ...customStyles,
    overlay: {
      ...customStyles.overlay,
      zIndex: 1100 || customStyles.overlay.zIndex, // Override zIndex
    },
  };
  const [subscriptionDetails, setSubscriptionDetails] = useState({
    subscription: subscription,
    address: {
      country: "",
      address: "",
      apartment: "",
      city: "",
      postalCode: "",
    },
    perfumes: [],
  });

  useEffect(() => {
    setSubscriptionDetails({
      subscription: subscription,
      address: {
        country: "",
        address: "",
        apartment: "",
        city: "",
        postalCode: "",
      },
      perfumes: [],
    });
  }, [subscription]);

  const handleAddressChange = (e) => {
    setSubscriptionDetails({
      ...subscriptionDetails,
      address: {
        ...subscriptionDetails.address,
        [e.target.name]: e.target.value,
      },
    });
  };

  const [perfumes_number, setPerfumes_number] = useState();

  const handleSubmit = async (event) => {
    const { address } = subscriptionDetails;
    if (
      !address.country.trim() ||
      !address.address.trim() ||
      !address.apartment.trim() ||
      !address.city.trim() ||
      !address.postalCode.trim()
    ) {
      toast.error("Please complete your address information");
      return;
    }

    if (subscriptionDetails.perfumes.length === 0 || perfumes_number !== 0) {
      toast.error("Please select perfumes");
      return;
    }

    dispatch(addSubscription({ subscriptionDetails, profileData }));
  };

  const [isOpenChoosePerfumeModal, setIsOpenChoosePerfumeModal] =
    useState(false);

  const openChoosePerfumeModal = () => {
    if (perfumes_number === 0) {
      toast.error("Cannot choose more scents");
      toast.error("Reset the scents and choose again");
      return;
    }
    setIsOpenChoosePerfumeModal(true);
  };

  const closeChoosePerfumeModal = () => {
    setIsOpenChoosePerfumeModal(false);
  };

  const resetPerfumes = () => {
    setPerfumes_number(subscription?.scents_number);
    setSubscriptionDetails({
      ...subscriptionDetails,
      perfumes: [],
    });
  };

  return (
    <>
      <ChoosePerfumeModal
        isOpenModal={isOpenChoosePerfumeModal}
        closeModal={closeChoosePerfumeModal}
        subscription={subscription}
        perfumes_number={perfumes_number}
        setPerfumes_number={setPerfumes_number}
        setSubscriptionDetail={setSubscriptionDetails}
      />

      <Modal
        isOpen={isOpenModal}
        onRequestClose={() => {
          setSubscriptionDetails({
            subscription: null,
            address: {
              country: "",
              address: "",
              apartment: "",
              city: "",
              postalCode: "",
            },
            perfumes: [],
          });
          closeModal();
        }}
        style={modalStyles}
      >
        <div className="w-11/12 mx-auto text-white p-2">
          <h1 className="text-xl md:text-4xl pb-7">Subscribe To :</h1>

          <div className="w-full border_2 p-3 helvetica">
            <div className="flex flex-row justify-between ">
              <div className="flex flex-col items-start ">
                <h1>{subscription?.scents_number} Scent per month</h1>
                <h3 className="text-sm opacity-80">
                  {subscription?.package_type} perfumes
                </h3>
              </div>
              <h1 className="opacity-80">${subscription?.price}</h1>
            </div>

            <hr className="w-full mt-4" />

            <div className="py-5 flex flex-col gap-2">
              <div className="flex flex-row justify-between">
                <h1>SubTotal:</h1>
                <h2>${subscription?.price}</h2>
              </div>

              <div className="flex flex-row justify-between">
                <h1>Shipping:</h1>
                <h2>Enter Shipping address</h2>
              </div>
              <div className="flex flex-row justify-between">
                <h1>Total</h1>
                <h2>${subscription?.price}</h2>
              </div>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row gap-2 mt-10 ">
            <div className="flex-1">
              <h1 className="text-xl md:text-3xl">Delivery Address</h1>

              <form
                id="deliveryAddress"
                className="flex flex-col gap-2 w-full mt-2"
              >
                <select
                  name="country"
                  value={subscriptionDetails.address.country}
                  onChange={handleAddressChange}
                  className="bg-transparent text-white"
                >
                  <option value="">Select Country</option>
                  {countries.map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
                <input
                  type="text"
                  name="address"
                  placeholder="Address"
                  value={subscriptionDetails.address.address}
                  onChange={handleAddressChange}
                />
                <input
                  type="text"
                  name="apartment"
                  placeholder="Apartment, suite, etc. (optional)"
                  value={subscriptionDetails.address.apartment}
                  onChange={handleAddressChange}
                />
                <div className="flex flex-col lg:flex-row gap-2">
                  <input
                    type="text"
                    name="city"
                    placeholder="City"
                    value={subscriptionDetails.address.city}
                    onChange={handleAddressChange}
                    className="flex-1"
                  />
                  <input
                    type="text"
                    name="postalCode"
                    placeholder="Postal Code"
                    value={subscriptionDetails.address.postalCode}
                    onChange={handleAddressChange}
                    className="flex-1"
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-5 items-center justify-center mt-3 ">
            <div
              className="bg-[#D9D9D9] px-6 py-2 cursor-pointer"
              onClick={resetPerfumes}
            >
              <span>Reset Perfumes</span>
            </div>

            <div
              className="bg-[#D9D9D9] px-6 py-2 cursor-pointer"
              onClick={openChoosePerfumeModal}
            >
              <span>Choose Perfume</span>
            </div>
            <div
              className="bg-[rgb(217,217,217)] px-6 py-2 cursor-pointer"
              onClick={handleSubmit}
            >
              <span>Checkout</span>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SubscriptionModal;
