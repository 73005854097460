import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: false,
  menData: [],
};

const forHimPageSlice = createSlice({
  name: "forHim",
  initialState,
  reducers: {
    getMenDataRequest: (state) => {
      state.loading = true;
      state.menData = [];
      state.error = false;
    },
    getMenDataSuccess: (state, action) => {
      state.loading = false;
      state.menData = action.payload;
      state.error = false;
    },
    getMenDataFailure: (state, action) => {
      state.loading = false;
      state.menData = [];
      state.error = action.payload;
    },
  },
});

export const ForHimPageAction = forHimPageSlice.actions;
export default forHimPageSlice;
