import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import ChangeProductModal from "./changeProductModal";
import ConfirmChoiceModal from "./confirmChoiceModal";
import { useSelector } from "react-redux";
import Loader from "../../../Components/Loader/loader";
import { customStyles } from "../../../Shared/customStyles";

const EditSubscriptionModal = ({ isOpen, onClose, subscription }) => {
  const [choosePerfumeModalOpen, setChoosePerfumeModalOpen] = useState(false);
  const [chosenProduct, setChosenProduct] = useState(null);
  const { loading } = useSelector((store) => store.subscriptionsReducer);
  const modalStyles = {
    ...customStyles,
    overlay: {
      ...customStyles.overlay,
      zIndex: 1100 || customStyles.overlay.zIndex, // Override zIndex
    },
  };

  const openChoosePerfumeModal = (product) => {
    setChosenProduct(product);
    setChoosePerfumeModalOpen(true);
  };
  const closeChoosePerfumeModal = () => {
    setChoosePerfumeModalOpen(false);
  };

  return (
    <>
      <ChangeProductModal
        isOpen={choosePerfumeModalOpen}
        closeModal={closeChoosePerfumeModal}
        subscription={subscription}
        chosenProduct={chosenProduct}
        closeChoosePerfumeModal={closeChoosePerfumeModal}
      ></ChangeProductModal>

      <Modal isOpen={isOpen} onRequestClose={onClose} style={modalStyles}>
        {!loading && (
          <div className="p-2">
            <h2 className="text-base lg:text-2xl font-bold mb-4 text-center">
              Edit Subscription
            </h2>
            <div className="space-y-4">
              <p className="text-center">
                <strong>Package:</strong> {subscription?.package?.name} -
                {subscription?.package?.price}$
              </p>
              <div className="w-full">
                <h1 className="w-full text-center lg:text-4xl">
                  Selected Product:
                </h1>{" "}
                <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                  {subscription?.products?.map((product) => (
                    <div
                      key={product.id}
                      className="flex flex-col gap-2 w-full bg-[#313131] text-white rounded-lg shadow-md p-4 hover:shadow-xl transition-shadow duration-300"
                    >
                      {/* Product Image */}
                      <div className="w-full mx-auto h-32">
                        <img
                          src={`${
                            process.env.REACT_APP_BASE_URL + product.image
                          }`}
                          alt={product.name}
                          className=" h-full rounded-md mx-auto"
                        />
                      </div>

                      {/* Product Name */}
                      <h3 className="text-lg text-center font-semibold text-[#e63946] mb-2">
                        {product.name}
                      </h3>

                      {/* Action Buttons */}
                      <div className="w-full mt-4 flex justify-between ">
                        <span className="text-lg font-bold text-[#f1faee]">
                          ${product.price}
                        </span>
                        <button
                          onClick={() => {
                            openChoosePerfumeModal(product);
                          }}
                          className="px-4 py-2 bg-[#457b9d] text-white rounded-md hover:bg-[#1d3557] transition duration-300"
                        >
                          Change
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center mt-8">
              <button
                className="bg-black text-white p-2 px-6 rounded-md text-center cursor-pointer"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        )}

        {loading && <Loader />}
      </Modal>
    </>
  );
};

export default EditSubscriptionModal;
