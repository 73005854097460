import React, { useEffect, useState } from "react";
import PerfumeCard from "../../Components/PerfumeCard/perfumeCard";
import Zoom from "../../Assets/AllProducts/carbon_zoom-in.png";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../Redux/Products/ProductsActions";
import { useLocation, useNavigate } from "react-router-dom";
import { addToCart } from "../../Redux/Cart/CartActions";
import { toast } from "react-toastify";
import { isTokenValid } from "../../utils/verifyToken";
import Modal from "react-modal";

import { Img } from "react-image";
import Loader from "../../Components/Loader/loader";
const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

const AddToCart = () => {
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();
  const { perfume } = location.state;

  const dispatch = useDispatch();
  const { products } = useSelector((store) => store.productsReducer);
  const [perfumes, setPerfumes] = useState([]);

  const [isOpenZoomOutModal, setIsOpenZoomOutModal] = useState(false);
  const openZoomOutModal = () => {
    console.log("Open Zoom Out Modal");
    setIsOpenZoomOutModal(true);
  };
  const closeZoomOutModal = () => {
    setIsOpenZoomOutModal(false);
  };

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  const handleAddToCart = () => {
    if (!isTokenValid()) {
      navigate("/auth");
      return;
    }
    dispatch(addToCart(perfume, quantity));
    toast.success("Perfume added to cart");
  };

  useEffect(() => {
    if (products.data && products.data.length > 0) {
      const filteredProducts = products.data.filter(
        (product) => product.category === perfume.category
      );
      const randomPerfumes = shuffleArray(filteredProducts).slice(0, 4);
      setPerfumes(randomPerfumes);
    }
  }, [products, perfume.category]);

  const customStyles = {
    content: {
      width: "60%",
      height: "60%",
      margin: "auto",
      padding: "40px",
      backgroundColor: "#313131",
      color: "white",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
  };

  return (
    <div className="w-full bg-[#313131] py-10 ">
      <Modal
        isOpen={isOpenZoomOutModal}
        onRequestClose={closeZoomOutModal}
        style={customStyles}
      >
        <div className="h-full flex items-center justify-center">
          <Img
            src={process.env.REACT_APP_BASE_URL + perfume?.image}
            alt="Perfume"
            className="w-32 sm:w-48 md:w-56 lg:w-64 m-auto"
            loader={<Loader />}
          />
        </div>
      </Modal>
      <div className="w-4/5 mx-auto flex flex-col lg:flex-row gap-3">
        {/* FIRST COLUMN */}
        <div className="h-96 flex-1 border p-14 flex justify-center relative">
          <Img
            src={process.env.REACT_APP_BASE_URL + perfume?.image}
            alt="Perfume"
            width={190}
            className="h-full"
            loader={<Loader />}
          />
          {/* Zoom Button */}
          <button
            onClick={(e) => {
              e.stopPropagation();
              openZoomOutModal();
            }}
            className="absolute bottom-2 right-2 bg-[#4a4a4a] text-black  p-3 shadow-md hover:bg-gray-300 transition f"
          >
            <img src={Zoom} alt="" className="w-2 sm:w-4 md:w-8" />
          </button>
        </div>

        {/* SECOND COLUMN */}
        <div className="flex-1 flex flex-col gap-2 text-white">
          <h1>Perfumster Fragrance</h1>
          <h1 className=" text-2xl">{perfume.name}</h1>
          <h1 className="text-4xl">${perfume.price}</h1>
          <p className="text-sm">
            Are you looking for the most exclusive perfume with ingredients
            source from wide range . Then Tom Ford is your fragrance with top
            notes of bergamot essence & green leaves, heart notes of amber melon
            and pineapple and base notes of woody, vanilla and must is the one
            for you
          </p>

          {/* <div className="">
            <h1 className="font-bold">Top Notes :</h1>
            <h1 className="opacity-60">Test Test Test</h1>
          </div>

          <div className="">
            <h1 className="font-bold">Top Notes :</h1>
            <h1 className="opacity-60">Test Test Test</h1>
          </div>

          <div className="">
            <h1 className="font-bold">Top Notes :</h1>
            <h1 className="opacity-60">Test Test Test</h1>
          </div> */}

          <hr />

          <h1>
            Size :{" "}
            <span className="opacity-60">3.4OZ/{perfume.perfume_size}</span>{" "}
          </h1>
          <div className="flex flex-row gap-2 items-center">
            <span>QTY:</span>
            <div className="flex flex-row gap-2 p-1 px-2 w-28 border justify-between">
              <span
                className="cursor-pointer"
                onClick={() => {
                  setQuantity((prev) => (prev > 1 ? prev - 1 : prev));
                }}
              >
                -
              </span>
              <span>{quantity}</span>
              <span
                className="cursor-pointer"
                onClick={() => {
                  setQuantity((prev) => prev + 1);
                }}
              >
                +
              </span>
            </div>
            <div
              className="bg-[#B99545] text-center text-white w-full p-2 cursor-pointer active:bg-[#9a7d37]"
              onClick={handleAddToCart}
            >
              Add To Cart
            </div>
          </div>
        </div>
      </div>

      <div className="w-full text-center mt-6 text-white text-4xl">
        <span>Similar Products</span>
      </div>

      <div className="w-4/5 mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 mt-10">
        {perfumes.slice(0, 4).map((element, index) => (
          <PerfumeCard perfume={element} />
        ))}
      </div>
    </div>
  );
};

export default AddToCart;
