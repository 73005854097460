import axios from "axios";
import { ContactUsPageAction } from "./ContactUsReducers";
import { toast } from "react-toastify";

export const sendEmail = (formData) => async (dispatch) => {
  try {
    dispatch(ContactUsPageAction.sendEmailRequest());

    const emailData = {
      service_id: process.env.REACT_APP_SERVICE_ID,
      template_id: process.env.REACT_APP_TEMPLATE_ID,
      user_id: process.env.REACT_APP_PUBLIC_KEY,
      template_params: {
        from_email: formData.email,
        from_name: formData.name,
        to_name: "support@perfumster.com",
        message: formData.message,
      },
    };

    const url = "https://api.emailjs.com/api/v1.0/email/send";
    const response = await axios.post(url, emailData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      toast.success("Email sent successfully");
    }

    dispatch(ContactUsPageAction.sendEmailSuccess(response.data));
  } catch (error) {
    toast.error("Failed to send email");
    dispatch(ContactUsPageAction.sendEmailFailure(error.message));
  }
};
