import React, { useEffect, useState } from "react";
import Recommended from "../../Assets/Women/recommended.webp";
import InitialBg from "../../Assets/Women/initialBg.png";
import PerfumeCard from "../../Components/PerfumeCard/perfumeCard";
import SpecialCard from "../../Components/specialsCard";
import "./style.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../Redux/Products/ProductsActions";
import { getWomenData } from "../../Redux/ForHer/ForHerActions";
import Footer1 from "../../Components/Footer1/footer1";

const ForHer = () => {
  const [chosen, setChosen] = useState("5ml");

  const [perfumes, setPerfumes] = useState([]);
  const [homeSection, setHomeSection] = useState([]);
  const [recommendedSection, setRecommendedSection] = useState([]);
  const [specialBackgroundsSection, setSpecialBackgroundsSection] = useState(
    []
  );
  const [specialPerfumesSection, setSpecialPerfumesSection] = useState([]);

  const dispatch = useDispatch();
  const { products } = useSelector((store) => store.productsReducer);
  const { womenData } = useSelector((store) => store.forHerReducer);

  useEffect(() => {
    dispatch(getProducts());
    dispatch(getWomenData());
  }, [dispatch]);
  const special_perfumes_data = [
    {
      brand: "Giorgio Armani",
      tagline: "Ocean of Freshness and Elegance",
      name: "Ocean Di Gioia EDP",
      description:
        "A vibrant fragrance inspired by the sea, blending juicy pear, water jasmine, and woody musk for a refreshing yet elegant experience.",
    },
    {
      brand: "Lancome",
      tagline: "Midnight Romance in Bloom",
      name: "Tresor Midnight Rose",
      description:
        "A seductive blend of raspberry, rose, and vanilla, this fragrance captures the magic and mystery of midnight.",
    },
    {
      brand: "Valentino",
      tagline: "Born in Roma, Raised in Elegance",
      name: "Donna Born in Roma EDP",
      description:
        "A modern and bold fragrance featuring jasmine, vanilla bourbon, and woody notes, celebrating individuality and sophistication.",
    },
  ];

  useEffect(() => {
    if (products.data) {
      // Filter and randomly pick up to 7 perfumes
      const filteredPerfumes = products?.data?.filter(
        (perfume) =>
          perfume.category === "Female" && perfume.perfume_size === chosen
      );
      const randomPerfumes = filteredPerfumes
        .sort(() => Math.random() - 0.5) // Randomize array order
        .slice(0, 7); // Take the first 7 items
      setPerfumes(randomPerfumes);
    }
    if (womenData.data) {
      setHomeSection(womenData.data.filter((data) => data.section === "home"));
      setRecommendedSection(
        womenData.data.filter((data) => data.section === "recommended")
      );
      setSpecialBackgroundsSection(
        womenData.data.filter((data) => data.section === "special_backgrounds")
      );
      setSpecialPerfumesSection(
        womenData.data.filter((data) => data.section === "special_perfumes")
      );
    }
  }, [products, womenData, chosen]);

  useEffect(() => {
    if (products.data) {
      // Filter and randomly pick up to 7 perfumes
      const filteredPerfumes = products?.data?.filter(
        (perfume) =>
          perfume.category === "Female" && perfume.perfume_size === chosen
      );
      const randomPerfumes = filteredPerfumes
        .sort(() => Math.random() - 0.5) // Randomize array order
        .slice(0, 7); // Take the first 7 items
      setPerfumes(randomPerfumes);
    }
  }, [chosen, products]);

  return (
    <>
      <div className="w-full bg-[#313131] pb-10">
        {/* Heading Image with Text */}
        <div
          className="w-full h-screen bg-cover bg-right flex items-start justify-center"
          style={{
            backgroundImage: `url(${
              homeSection[0]?.content
                ? process.env.REACT_APP_BASE_URL + homeSection[0]?.content
                : InitialBg
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        >
          <div className="w-full h-full px-2 lg:px-0 lg:w-4/5 mx-auto pt-5 flex flex-col justify-start md:justify-center gap-2">
            <h1 className="text-white text-sm font-thin">Women Colognes</h1>
            <h2 className="text-white text-4xl md:text-6xl font-thin w-3/4 lg:w-2/3 ">
              Discover the Essence <br /> of Femininity
            </h2>
            <p className="w-1/3 hidden md:block text-white font-thin opacity-80 text-sm">
              Rhoncus morbi et augue nec, in id ullamcorper at sit. Condimentum
              sit nunc in eros scelerisque sed. Commodo in viverra nunc,
              ullamcorper ut. Non, amet, aliquet scelerisque nullam sagittis,
              pulvinar. Fermentum scelerisque sit consectetur hac mi. Mollis leo
              eleifend ultricies purus iaculis.
            </p>
            <div className="flex flex-row gap-2 mt-5">
              <Link to={"/products"}>
                <div className="bg-white text-black p-2 px-3 md:p-3 md:px-6 font-thin text-sm cursor-pointer">
                  Explore our collection
                </div>
              </Link>
            </div>
          </div>
        </div>

        {/* Highly Recommended Image + Title */}
        <div className="py-9">
          <div className="w-full text-center">
            <h1 className="text-white text-3xl md:text-5xl">
              Highly recommended
            </h1>
          </div>

          <div className="w-full md:w-4/5 mx-auto mt-10 relative">
            <img src={Recommended} alt="" className="w-full h-full" />

            <img
              src={
                process.env.REACT_APP_BASE_URL + recommendedSection[0]?.content
              }
              alt="Perfume"
              class="perfume_women"
            />

            <img
              src={
                process.env.REACT_APP_BASE_URL + recommendedSection[1]?.content
              }
              alt="Perfume"
              class="perfume_women_2"
            />
          </div>
        </div>

        {/* Specials */}
        <div className="py-9">
          <div className="w-full text-center">
            <h1 className=" text-white text-3xl md:text-5xl">Specials</h1>
          </div>

          <div className="w-4/5 m-auto mt-10 grid grid-cols-1 custom-grid gap-2">
            {specialPerfumesSection.map((element, index) => (
              <div key={index} className="w-full flex">
                <SpecialCard
                  index={index + 4}
                  perfume={element}
                  backgroundImage={specialBackgroundsSection[index]?.content}
                  special_perfumes_data={special_perfumes_data[index]}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Product List */}
        <div className="py-9">
          <div className="w-full text-center text-white">
            <h1 className=" text-xl md:text-3xl">Product List</h1>
          </div>

          <div className="w-2/3 md:1/2 lg:w-1/3 bg-[#454545] flex flex-row items-center justify-center m-auto mt-10 text-center">
            <div
              className={`${
                chosen === "5ml" ? "text-black bg-white" : "text-slate-200"
              } flex-1 p-3 px-5 md:py-4 md:px-10 cursor-pointer`}
              onClick={() => setChosen("5ml")}
            >
              <span>5ml</span>
            </div>
            <div
              className={`${
                chosen === "10ml" ? "text-black bg-white" : "text-slate-200 "
              } flex-1  p-3 px-5 md:py-4 md:px-10 cursor-pointer`}
              onClick={() => setChosen("10ml")}
            >
              <span>10ml</span>
            </div>
          </div>
        </div>

        <div className="w-11/12 mx-auto py-10">
          <div className="w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4  gap-4 gap-y-10">
            {perfumes?.map((element, index) => (
              <PerfumeCard perfume={element} key={index} />
            ))}
          </div>
        </div>

        <div className="w-full flex flex-row items-center justify-center mt-10">
          <Link to={"/products"}>
            <div className="p-2 bg-white text-black cursor-pointer">
              Explore our collection
            </div>
          </Link>
        </div>
      </div>

      <Footer1 />
    </>
  );
};

export default ForHer;
