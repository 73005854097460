import React from "react";
import { Link } from "react-router-dom";

const PaymentFailure = () => {
  return (
    <div className="min-h-screen bg-[#313131] flex items-center justify-center text-center">
      <div className="text-white p-8">
        <h1 className="text-8xl font-bold text-[#e63946] drop-shadow-lg mb-4">
          Failed
        </h1>
        <h2 className="text-3xl font-semibold text-[#f1faee] mb-4">
          Payment Failed
        </h2>
        <p className="text-lg text-[#a8dadc] mb-8">
          Oops! Something went wrong with your payment. Please try again.
        </p>
        <Link to="/checkout">
          <h1 className="inline-block px-6 py-3 bg-[#457b9d] text-white rounded-md transition duration-300 ease-in-out hover:bg-[#1d3557] text-lg">
            Try Again
          </h1>
        </Link>
      </div>
    </div>
  );
};

export default PaymentFailure;
