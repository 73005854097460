import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: false,
  resendVerificationError: false,
  login: {},
  signup: {},

  requestOtpLoading: false,
  requestOtp: {},
  requestOtpError: false,

  changePasswordLoading: false,
  passwordChanged: {},
  changePasswordError: false,
};

const authPageSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    getLoginRequest: (state, action) => {
      state.loading = true;
      state.error = false;
      state.login = {};
      state.signup = {};
    },
    getLoginSuccess: (state, action) => {
      state.loading = false;
      state.error = false;
      state.login = action.payload;
      state.signup = {};
    },
    getLoginFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.login = {};
      state.signup = {};
    },

    getSignUpRequest: (state, action) => {
      state.loading = true;
      state.error = false;
      state.signup = {};
      state.login = {};
    },
    getSignUpSuccess: (state, action) => {
      state.loading = false;
      state.error = false;
      state.signup = action.payload;
      state.login = {};
    },
    getSignUpFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.signup = {};
      state.login = {};
    },

    getResendVerificationRequest: (state, action) => {
      state.loading = true;
      state.resendVerificationError = false;
    },
    getResendVerificationSuccess: (state, action) => {
      state.loading = false;
      state.resendVerificationError = false;
    },
    getResendVerificationFailure: (state, action) => {
      state.loading = false;
      state.resendVerificationError = action.payload;
    },

    getRequestOtpRequest: (state, action) => {
      state.requestOtpLoading = true;
      state.requestOtp = {};
      state.requestOtpError = false;
    },

    getRequestOtpSuccess: (state, action) => {
      state.requestOtpLoading = false;
      state.requestOtp = action.payload;
      state.requestOtpError = false;
    },

    getRequestOtpFailure: (state, action) => {
      state.requestOtpLoading = false;
      state.requestOtpError = action.payload;
      state.requestOtp = {};
    },

    getChangePasswordRequest: (state, action) => {
      state.changePasswordLoading = true;
      state.passwordChanged = {};
      state.changePasswordError = false;
    },
    getChangePasswordSuccess: (state, action) => {
      state.changePasswordLoading = false;
      state.passwordChanged = action.payload;
      state.changePasswordError = false;
    },
    getChangePasswordFailure: (state, action) => {
      state.changePasswordLoading = false;
      state.changePasswordError = action.payload;
      state.passwordChanged = {};
    },
    resetRequestOtp: (state) => {
      state.requestOtp = {};
      state.requestOtpError = false;
      state.requestOtpLoading = false;
    },

    resetPasswordChanged: (state) => {
      state.passwordChanged = {};
      state.changePasswordError = false;
      state.changePasswordLoading = false;
    },
  },
});

export const AuthPageAction = authPageSlice.actions;

export default authPageSlice;
